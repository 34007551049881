// extracted by mini-css-extract-plugin
export var column = "UserEditProfileGeneralTab__column__uvc4e";
export var container = "UserEditProfileGeneralTab__container__jII_W";
export var editProfileForm = "UserEditProfileGeneralTab__editProfileForm__sE4ao";
export var emptyLast = "UserEditProfileGeneralTab__emptyLast__Q90M_";
export var error = "UserEditProfileGeneralTab__error__ARPBh";
export var field = "UserEditProfileGeneralTab__field__CXTCP";
export var fieldColumn = "UserEditProfileGeneralTab__fieldColumn__bQz43";
export var fieldHint = "UserEditProfileGeneralTab__fieldHint__iJaAP";
export var fieldRow = "UserEditProfileGeneralTab__fieldRow__ZHyIe";
export var fieldRowInPanel = "UserEditProfileGeneralTab__fieldRowInPanel__PhjVa";
export var fieldRowPassword = "UserEditProfileGeneralTab__fieldRowPassword__uwX7W";
export var flex = "UserEditProfileGeneralTab__flex__ua27s";
export var flexColumn = "UserEditProfileGeneralTab__flexColumn__BMUrs";
export var gap1 = "UserEditProfileGeneralTab__gap1__AlW7R";
export var gap2 = "UserEditProfileGeneralTab__gap2__hsB8t";
export var gap3 = "UserEditProfileGeneralTab__gap3__nOq4h";
export var gap4 = "UserEditProfileGeneralTab__gap4__V_0yS";
export var gap5 = "UserEditProfileGeneralTab__gap5__U0clR";
export var messageText = "UserEditProfileGeneralTab__messageText__hnkNN";
export var paddingHorizontalZero = "UserEditProfileGeneralTab__paddingHorizontalZero__nG4rU";
export var row = "UserEditProfileGeneralTab__row__W1ymy";
export var titleMain = "UserEditProfileGeneralTab__titleMain__olQJy";
export var twoInRow = "UserEditProfileGeneralTab__twoInRow__MuEdc";