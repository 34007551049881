// extracted by mini-css-extract-plugin
export var column = "UserEditProfileChangePasswordTab__column__dAojC";
export var container = "UserEditProfileChangePasswordTab__container__Tmfcz";
export var editProfileFormChangePassword = "UserEditProfileChangePasswordTab__editProfileFormChangePassword__SeA7C";
export var error = "UserEditProfileChangePasswordTab__error__FYBif";
export var fieldColumn = "UserEditProfileChangePasswordTab__fieldColumn__gNuAW";
export var fieldRow = "UserEditProfileChangePasswordTab__fieldRow__cRrJV";
export var flex = "UserEditProfileChangePasswordTab__flex__MjmrM";
export var flexColumn = "UserEditProfileChangePasswordTab__flexColumn__RJUYl";
export var formTitle = "UserEditProfileChangePasswordTab__formTitle__Jttae";
export var gap1 = "UserEditProfileChangePasswordTab__gap1__KkQTF";
export var gap2 = "UserEditProfileChangePasswordTab__gap2__DiuFj";
export var gap3 = "UserEditProfileChangePasswordTab__gap3__lbof1";
export var gap4 = "UserEditProfileChangePasswordTab__gap4__wAZGI";
export var gap5 = "UserEditProfileChangePasswordTab__gap5__Of0WL";
export var labelInfo = "UserEditProfileChangePasswordTab__labelInfo__lBc1U";
export var medium = "UserEditProfileChangePasswordTab__medium__oewIt";
export var messageText = "UserEditProfileChangePasswordTab__messageText__jg7AI";
export var paddingHorizontalZero = "UserEditProfileChangePasswordTab__paddingHorizontalZero__hG4b2";
export var passwordStrenght = "UserEditProfileChangePasswordTab__passwordStrenght__hH2TJ";
export var progress = "UserEditProfileChangePasswordTab__progress__f_ZEY";
export var progressBar = "UserEditProfileChangePasswordTab__progressBar__HlWsB";
export var row = "UserEditProfileChangePasswordTab__row__A01lT";
export var strong = "UserEditProfileChangePasswordTab__strong__YIxwA";
export var title = "UserEditProfileChangePasswordTab__title__WSwtI";
export var titleMain = "UserEditProfileChangePasswordTab__titleMain__SMm1g";