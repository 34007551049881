// extracted by mini-css-extract-plugin
export var buttons = "Uploader__buttons__CdEZ2";
export var column = "Uploader__column__U4WZs";
export var flex = "Uploader__flex__JWBHC";
export var flexColumn = "Uploader__flexColumn__ZqoW2";
export var gap1 = "Uploader__gap1__zmUjm";
export var gap2 = "Uploader__gap2__inNkb";
export var gap3 = "Uploader__gap3__pfAih";
export var gap4 = "Uploader__gap4__Kw8O5";
export var gap5 = "Uploader__gap5__cRClk";
export var icon = "Uploader__icon__PnMkS";
export var input = "Uploader__input__snrSX";
export var overlay = "Uploader__overlay__oY4_m";
export var row = "Uploader__row__p_JPq";
export var wrapper = "Uploader__wrapper__fGpLI";