// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as P from "../../../styleguide/components/Paragraph/P.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as User from "../../../models/User.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as IconInfo from "../../../styleguide/icons/IconInfo.res.js";
import * as TopNavbar from "../top-navbar/TopNavbar.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserEditProfileData from "./UserEditProfileData.res.js";
import * as UserEditProfileScss from "./UserEditProfile.scss";
import * as UserEditProfileGeneralTab from "./UserEditProfileGeneralTab.res.js";
import * as UserProfileEditorActionBar from "./UserProfileEditorActionBar.res.js";
import * as UserEditProfileChangePasswordTab from "./UserEditProfileChangePasswordTab.res.js";
import * as UserEditProfileChangePasswordData from "./UserEditProfileChangePasswordData.res.js";

var css = UserEditProfileScss;

function UserEditProfile$UserEditProfile(props) {
  var user = props.user;
  var editingAgainOpenTimeoutId = React.useRef(undefined);
  var urlVisitTimeoutId = React.useRef(undefined);
  var initialState = React.useMemo((function () {
          return {
                  status: "Editing",
                  selectedOption: "GeneralInfo",
                  input: UserEditProfileData.Input.empty(user),
                  results: undefined,
                  inputChangePassword: UserEditProfileChangePasswordData.Input.empty(),
                  resultsChangePassword: undefined,
                  submissionError: false,
                  unsavedChanges: false,
                  unsavedChangePasswordChanges: false,
                  message: undefined,
                  isSuccessMessage: false
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "Submit" :
                  var match = state.status;
                  if (match === "Editing") {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: state.status,
                              selectedOption: state.selectedOption,
                              input: state.input,
                              results: UserEditProfileData.Validate.all(state.input),
                              inputChangePassword: state.inputChangePassword,
                              resultsChangePassword: state.resultsChangePassword,
                              submissionError: state.submissionError,
                              unsavedChanges: state.unsavedChanges,
                              unsavedChangePasswordChanges: state.unsavedChangePasswordChanges,
                              message: state.message,
                              isSuccessMessage: state.isSuccessMessage
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                if (UserEditProfileData.Validate.valid(Belt_Option.getExn(param.state.results))) {
                                  return dispatch("SaveChanges");
                                } else {
                                  return dispatch({
                                              TAG: "ShowMessage",
                                              _0: "Please fill all the required fields.",
                                              _1: false
                                            });
                                }
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SubmitChangePassword" :
                  var match$1 = state.status;
                  if (match$1 === "Editing") {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: state.status,
                              selectedOption: state.selectedOption,
                              input: state.input,
                              results: state.results,
                              inputChangePassword: state.inputChangePassword,
                              resultsChangePassword: UserEditProfileChangePasswordData.Validate.all(state.inputChangePassword),
                              submissionError: state.submissionError,
                              unsavedChanges: state.unsavedChanges,
                              unsavedChangePasswordChanges: state.unsavedChangePasswordChanges,
                              message: state.message,
                              isSuccessMessage: state.isSuccessMessage
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                if (UserEditProfileChangePasswordData.Validate.valid(Belt_Option.getExn(param.state.resultsChangePassword))) {
                                  return dispatch("SaveChangesChangePassword");
                                } else {
                                  return dispatch({
                                              TAG: "ShowMessage",
                                              _0: "Please fill all the required fields.",
                                              _1: false
                                            });
                                }
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Validate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            selectedOption: state.selectedOption,
                            input: state.input,
                            results: UserEditProfileData.Validate.all(state.input),
                            inputChangePassword: state.inputChangePassword,
                            resultsChangePassword: state.resultsChangePassword,
                            submissionError: state.submissionError,
                            unsavedChanges: state.unsavedChanges,
                            unsavedChangePasswordChanges: state.unsavedChangePasswordChanges,
                            message: state.message,
                            isSuccessMessage: state.isSuccessMessage
                          }
                        };
              case "ValidateChangePassword" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            selectedOption: state.selectedOption,
                            input: state.input,
                            results: state.results,
                            inputChangePassword: state.inputChangePassword,
                            resultsChangePassword: UserEditProfileChangePasswordData.Validate.all(state.inputChangePassword),
                            submissionError: state.submissionError,
                            unsavedChanges: state.unsavedChanges,
                            unsavedChangePasswordChanges: state.unsavedChangePasswordChanges,
                            message: state.message,
                            isSuccessMessage: state.isSuccessMessage
                          }
                        };
              case "SaveChanges" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "Submitting",
                            selectedOption: state.selectedOption,
                            input: state.input,
                            results: state.results,
                            inputChangePassword: state.inputChangePassword,
                            resultsChangePassword: state.resultsChangePassword,
                            submissionError: state.submissionError,
                            unsavedChanges: state.unsavedChanges,
                            unsavedChangePasswordChanges: state.unsavedChangePasswordChanges,
                            message: state.message,
                            isSuccessMessage: state.isSuccessMessage
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api.updateUserProfile(param.state.input), (function (x) {
                                      if (x.TAG !== "Ok") {
                                        return dispatch({
                                                    TAG: "ShowMessage",
                                                    _0: "You have entered invalid current password!",
                                                    _1: false
                                                  });
                                      }
                                      dispatch("RemoveUnsavedChanges");
                                      dispatch({
                                            TAG: "ShowMessage",
                                            _0: "Your profile has been updated!",
                                            _1: true
                                          });
                                    }));
                            })
                        };
              case "SaveChangesChangePassword" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "Submitting",
                            selectedOption: state.selectedOption,
                            input: state.input,
                            results: state.results,
                            inputChangePassword: state.inputChangePassword,
                            resultsChangePassword: state.resultsChangePassword,
                            submissionError: state.submissionError,
                            unsavedChanges: state.unsavedChanges,
                            unsavedChangePasswordChanges: state.unsavedChangePasswordChanges,
                            message: state.message,
                            isSuccessMessage: state.isSuccessMessage
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api.updateUserProfileChangePassword(param.state.inputChangePassword), (function (x) {
                                      if (x.TAG === "Ok") {
                                        dispatch("RemoveUnsavedChanges");
                                        dispatch("EditingAgainOpen");
                                        dispatch({
                                              TAG: "ShowMessage",
                                              _0: "Your password has been changed, please login with new password.",
                                              _1: true
                                            });
                                        urlVisitTimeoutId.current = Caml_option.some(setTimeout((function () {
                                                    Url.visit("/");
                                                  }), 6000));
                                        return ;
                                      }
                                      dispatch("EditingAgainOpen");
                                      dispatch({
                                            TAG: "ShowMessage",
                                            _0: "You have entered invalid current password!",
                                            _1: false
                                          });
                                    }));
                            })
                        };
              case "EditingAgainOpen" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "Editing",
                            selectedOption: state.selectedOption,
                            input: state.input,
                            results: state.results,
                            inputChangePassword: state.inputChangePassword,
                            resultsChangePassword: state.resultsChangePassword,
                            submissionError: state.submissionError,
                            unsavedChanges: state.unsavedChanges,
                            unsavedChangePasswordChanges: state.unsavedChangePasswordChanges,
                            message: undefined,
                            isSuccessMessage: state.isSuccessMessage
                          }
                        };
              case "CancelPassword" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            selectedOption: state.selectedOption,
                            input: state.input,
                            results: state.results,
                            inputChangePassword: UserEditProfileChangePasswordData.Input.empty(),
                            resultsChangePassword: undefined,
                            submissionError: state.submissionError,
                            unsavedChanges: state.unsavedChanges,
                            unsavedChangePasswordChanges: false,
                            message: state.message,
                            isSuccessMessage: state.isSuccessMessage
                          }
                        };
              case "ResetUser" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            selectedOption: state.selectedOption,
                            input: UserEditProfileData.Input.empty(user),
                            results: state.results,
                            inputChangePassword: state.inputChangePassword,
                            resultsChangePassword: state.resultsChangePassword,
                            submissionError: state.submissionError,
                            unsavedChanges: false,
                            unsavedChangePasswordChanges: state.unsavedChangePasswordChanges,
                            message: state.message,
                            isSuccessMessage: state.isSuccessMessage
                          },
                          _1: (function (param) {
                              param.dispatch("Validate");
                            })
                        };
              case "RemoveUnsavedChanges" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            selectedOption: state.selectedOption,
                            input: state.input,
                            results: state.results,
                            inputChangePassword: state.inputChangePassword,
                            resultsChangePassword: state.resultsChangePassword,
                            submissionError: state.submissionError,
                            unsavedChanges: false,
                            unsavedChangePasswordChanges: false,
                            message: state.message,
                            isSuccessMessage: state.isSuccessMessage
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "Update" :
                  var input = action._0;
                  var match$2 = state.status;
                  if (match$2 !== "Editing") {
                    return "NoUpdate";
                  }
                  var match$3 = state.results;
                  if (match$3 !== undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: state.status,
                              selectedOption: state.selectedOption,
                              input: input,
                              results: state.results,
                              inputChangePassword: state.inputChangePassword,
                              resultsChangePassword: state.resultsChangePassword,
                              submissionError: state.submissionError,
                              unsavedChanges: true,
                              unsavedChangePasswordChanges: state.unsavedChangePasswordChanges,
                              message: state.message,
                              isSuccessMessage: state.isSuccessMessage
                            },
                            _1: (function (param) {
                                param.dispatch("Validate");
                              })
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              selectedOption: state.selectedOption,
                              input: input,
                              results: state.results,
                              inputChangePassword: state.inputChangePassword,
                              resultsChangePassword: state.resultsChangePassword,
                              submissionError: state.submissionError,
                              unsavedChanges: true,
                              unsavedChangePasswordChanges: state.unsavedChangePasswordChanges,
                              message: state.message,
                              isSuccessMessage: state.isSuccessMessage
                            }
                          };
                  }
              case "UpdateChangePassword" :
                  var inputChangePassword = action._0;
                  var match$4 = state.status;
                  if (match$4 !== "Editing") {
                    return "NoUpdate";
                  }
                  var match$5 = state.resultsChangePassword;
                  if (match$5 !== undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: state.status,
                              selectedOption: state.selectedOption,
                              input: state.input,
                              results: state.results,
                              inputChangePassword: inputChangePassword,
                              resultsChangePassword: state.resultsChangePassword,
                              submissionError: state.submissionError,
                              unsavedChanges: state.unsavedChanges,
                              unsavedChangePasswordChanges: true,
                              message: state.message,
                              isSuccessMessage: state.isSuccessMessage
                            },
                            _1: (function (param) {
                                param.dispatch("ValidateChangePassword");
                              })
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              selectedOption: state.selectedOption,
                              input: state.input,
                              results: state.results,
                              inputChangePassword: inputChangePassword,
                              resultsChangePassword: state.resultsChangePassword,
                              submissionError: state.submissionError,
                              unsavedChanges: state.unsavedChanges,
                              unsavedChangePasswordChanges: true,
                              message: state.message,
                              isSuccessMessage: state.isSuccessMessage
                            }
                          };
                  }
              case "ToggleOptions" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            selectedOption: action._0,
                            input: state.input,
                            results: state.results,
                            inputChangePassword: state.inputChangePassword,
                            resultsChangePassword: state.resultsChangePassword,
                            submissionError: state.submissionError,
                            unsavedChanges: state.unsavedChanges,
                            unsavedChangePasswordChanges: state.unsavedChangePasswordChanges,
                            message: undefined,
                            isSuccessMessage: state.isSuccessMessage
                          }
                        };
              case "ShowMessage" :
                  var message = action._0;
                  if (message !== undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: state.status,
                              selectedOption: state.selectedOption,
                              input: state.input,
                              results: state.results,
                              inputChangePassword: state.inputChangePassword,
                              resultsChangePassword: state.resultsChangePassword,
                              submissionError: state.submissionError,
                              unsavedChanges: state.unsavedChanges,
                              unsavedChangePasswordChanges: state.unsavedChangePasswordChanges,
                              message: message,
                              isSuccessMessage: action._1
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                editingAgainOpenTimeoutId.current = Caml_option.some(setTimeout((function () {
                                            dispatch("EditingAgainOpen");
                                          }), 5000));
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          return (function () {
                    Belt_Option.getWithDefault(Belt_Option.map(editingAgainOpenTimeoutId.current, (function (prim) {
                                clearTimeout(prim);
                              })), undefined);
                    Belt_Option.getWithDefault(Belt_Option.map(urlVisitTimeoutId.current, (function (prim) {
                                clearTimeout(prim);
                              })), undefined);
                  });
        }), []);
  var match$1 = state.selectedOption;
  var tmp;
  if (match$1 === "GeneralInfo") {
    var message = state.message;
    var match$2 = state.status;
    tmp = JsxRuntime.jsx(UserEditProfileGeneralTab.make, {
          input: state.input,
          results: state.results,
          update: (function (input) {
              dispatch({
                    TAG: "Update",
                    _0: input
                  });
            }),
          submit: (function (param) {
              dispatch("Submit");
            }),
          userRole: user.role,
          children: JsxRuntime.jsxs(UserProfileEditorActionBar.make, {
                children: [
                  JsxRuntime.jsx(A.make, {
                        href: "mailto:info@datacenters.com?subject=Account Deletion Request",
                        className: css.link,
                        children: "Request to Delete Account"
                      }),
                  JsxRuntime.jsx(UserProfileEditorActionBar.Message.make, {
                        className: css.editProfileMessage,
                        children: message !== undefined ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(P.make, {
                                            children: message
                                          }),
                                      className: css.message
                                    }),
                                className: Cx.cx([
                                      css.messageContainer,
                                      state.isSuccessMessage ? css.success : ""
                                    ])
                              }) : null
                      }),
                  state.unsavedChanges ? JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx("p", {
                                  children: "You have unsaved changes",
                                  className: css.noteText
                                }),
                            JsxRuntime.jsx(Control.AsLink.make, {
                                  className: css.link,
                                  onClick: (function (param) {
                                      dispatch("ResetUser");
                                    }),
                                  children: "Reset them"
                                }),
                            JsxRuntime.jsx("span", {
                                  children: JsxRuntime.jsx(IconInfo.make, {
                                        title: "Show List",
                                        size: "MD",
                                        color: "DarkOrange"
                                      }),
                                  className: css.noteIcon
                                })
                          ],
                          className: css.noteChanges
                        }) : JsxRuntime.jsx("div", {
                          className: css.noteChanges
                        }),
                  JsxRuntime.jsx(Button.make, {
                        size: "MD",
                        color: "Primary",
                        expanded: false,
                        busy: match$2 !== "Editing",
                        submit: true,
                        children: "Save Changes"
                      })
                ]
              })
        });
  } else {
    var message$1 = state.message;
    var match$3 = state.status;
    tmp = JsxRuntime.jsx(UserEditProfileChangePasswordTab.make, {
          input: state.inputChangePassword,
          results: state.resultsChangePassword,
          update: (function (inputChangePassword) {
              dispatch({
                    TAG: "UpdateChangePassword",
                    _0: inputChangePassword
                  });
            }),
          submit: (function (param) {
              dispatch("SubmitChangePassword");
            }),
          children: JsxRuntime.jsxs(UserProfileEditorActionBar.make, {
                children: [
                  JsxRuntime.jsx(A.make, {
                        href: "mailto:info@datacenters.com?subject=Account Deletion Request",
                        className: css.link,
                        children: "Request to Delete Account"
                      }),
                  JsxRuntime.jsx(UserProfileEditorActionBar.Message.make, {
                        className: css.passwordMessage,
                        children: message$1 !== undefined ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(P.make, {
                                            children: message$1
                                          }),
                                      className: css.message
                                    }),
                                className: Cx.cx([
                                      css.messageContainer,
                                      state.isSuccessMessage ? css.success : ""
                                    ])
                              }) : null
                      }),
                  state.unsavedChangePasswordChanges ? JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsx(Control.AsLink.make, {
                                className: css.link,
                                onClick: (function (param) {
                                    dispatch("CancelPassword");
                                  }),
                                children: "Cancel"
                              }),
                          className: Cx.cx([
                                css.noteChanges,
                                css.cancelPassword
                              ])
                        }) : JsxRuntime.jsx("div", {
                          className: css.noteChanges
                        }),
                  JsxRuntime.jsx(Button.make, {
                        size: "MD",
                        color: "Primary",
                        expanded: false,
                        busy: match$3 !== "Editing",
                        submit: true,
                        children: "Save Password"
                      })
                ]
              })
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(TopNavbar.make, {
                      selectedCategory: "EditProfile",
                      userRole: user.role
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "Edit Profile",
                                    className: css.title
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: "General Information",
                                            className: Cx.cx([
                                                  css.linkContainer,
                                                  state.selectedOption === "GeneralInfo" ? css.underlinedLink : ""
                                                ]),
                                            onClick: (function (param) {
                                                dispatch({
                                                      TAG: "ToggleOptions",
                                                      _0: "GeneralInfo"
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "Change Password",
                                            className: Cx.cx([
                                                  css.linkContainer,
                                                  state.selectedOption === "ChangePassword" ? css.underlinedLink : ""
                                                ]),
                                            onClick: (function (param) {
                                                dispatch({
                                                      TAG: "ToggleOptions",
                                                      _0: "ChangePassword"
                                                    });
                                              })
                                          })
                                    ],
                                    className: css.linkContainerList
                                  })
                            ],
                            className: css.contentWrapper
                          }),
                      className: css.barHeading
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: tmp,
                            className: css.innerWrap
                          }),
                      className: css.contentWrapper
                    })
              ],
              className: css.container
            });
}

var UserEditProfile = {
  css: css,
  Input: undefined,
  Validate: undefined,
  ValidationResult: undefined,
  InputChangePassword: undefined,
  ValidateChangePassword: undefined,
  ValidationChangePassword: undefined,
  make: UserEditProfile$UserEditProfile
};

function UserEditProfile$default(props) {
  return JsxRuntime.jsx(UserEditProfile$UserEditProfile, {
              user: User.fromJs(props.props.user)
            });
}

var $$default = UserEditProfile$default;

export {
  UserEditProfile ,
  $$default as default,
}
/* css Not a pure module */
